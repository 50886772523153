import {mapGetters, mapActions, mapMutations} from 'vuex';


export default {
  name: 'banner',
  props: {
    list: Array,
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
    })
  },
  components: {
  },
  directives: {},
  watch: {},
  methods: {
    ...mapActions({
    }),
    ...mapMutations({}),
  },
  beforeDestroy() {
  },
}
