import {mapGetters, mapActions, mapMutations} from 'vuex';
import {chunk} from 'lodash'
import homeSlider from './components/slider/index.vue'
import homeBanner from './components/banner/index.vue'
import homeProduct from '@/components/product/index.vue'
import btnLoadMore from '@/components/buttons/load-more/index.vue'

export default {
  name: 'home',
  data() {
    return {}
  },
  created() {
    this.fetchCategories()
    let currency = localStorage.getItem('currency')
    if (!currency) {
      const parsed = JSON.stringify("UAH");
      localStorage.setItem('currency', parsed)
    }
    if (this.$route.query.hasOwnProperty('token') && this.$route.query.hasOwnProperty('email')) {
      this.changePopupSetNewPassword(true);
    }
    this.changeCategory('popular')
  },
  mounted() {

    if (!this.homeSlider) {
      this.fetchHomeSlider();
    }
    if (!this.bannerList) {
      this.fetchBannerList();
    }
    if (this.listProduct === null) {
      // this.fetchProductList('popular').then(() => {
      // }).catch(error => {
      //   console.log(error)
      // });
    }
  },
  computed: {
    ...mapGetters({
      homeSlider: 'home/homeSlider',
      homeSliderLoading: 'home/homeSliderLoading',
      bannerList: 'home/bannerList',
      bannerListLoading: 'home/bannerListLoading',
      listProduct: 'home/list',
      listProductLength: 'home/listLength',
      listProductLoading: 'home/listLoading',
      activeProductCategory: 'home/activeCategory',
      isAuthenticated: `auth/isAuthenticated`,
      wishList: 'favorites/whichList',
      categoriesList: 'home/categoriesList'
    }),
    homeProducts() {
      if (this.isAuthenticated) {
        if (!this.listProduct) return []
        if (!this.wishList) return []
        return this.listProduct.map(e => {
          const item = this.wishList.find(t => t.id === e.id)
          e.selected = !!item
          return e
        })
      } else {
        return this.listProduct
      }
    }
  },
  components: {
    homeSlider,
    homeBanner,
    homeProduct,
    btnLoadMore
  },
  directives: {},
  watch: {},
  methods: {
    ...mapActions({
      fetchHomeSlider: `home/GET_HOME_SLIDER`,
      fetchBannerList: `home/GET_BANNER_LIST`,
      fetchProductList: `home/GET_PRODUCTS_LIST`,
      fetchCategories: 'home/GET_CATEGORIES'
    }),
    ...mapMutations({
      changeProductsCategory: `home/CHANGE_CATEGORY`,
      changePopupSetNewPassword: 'auth/CHANGE_POPUP_SET_NEW_PASSWORD',
      resetProducts: 'home/RESET_PRODUCTS'
    }),
    changeCategory(category) {
      this.resetProducts()
      this.changeProductsCategory(category)
      this.fetchProductList(category);
    },
  },
  beforeDestroy() {
  },
}
