export default {
  name: 'button-load-more',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    totalListLength: {
      type: Number,
      default: 0
    },
    listLength: {
      type: Number,
      default: 0
    }
  },
  methods: {
    changeBtn(){
      this.$emit('change-btn')
    }
  }
}
